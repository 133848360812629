header {
  
  .container {
    position:relative;
    height:90px;
  }
  
  .offcanvas-toggle {
    display: block;
    height: 34px;
    width: 34px;
    padding:10px 6px 0 6px;
    margin:17px 10px 0 0;
    cursor:pointer;
    
    // Bars
    .icon-bar {
      display: block;
      width: 22px;
      height: 2px;
      border-radius: 1px;
      background-color:$gray-dark;
    }
    
    .icon-bar + .icon-bar {
      margin-top: 4px;
    }
  }

  .btn-toggle-search {
    border-color:transparent;
    border-radius:0;
    font-size:24px;
    height:70px;
    width:60px;
    padding-top:18px;
    color:$gray-dark;
    background-color:$gray-light;
    &:hover,
    &:focus,
    &:active {
      color:$gray-dark;
      background-color:$gray-light;
      border-color:transparent;
      @include box-shadow(none);
    }
    
    &.collapsed,
    &.collapsed:hover,
    &.collapsed:focus,
    &.collapsed:active  {
      color:$gray-dark;
      background-color: transparent;
      @include box-shadow(none);  
    }
    
    
  }

  .logo {
    width:135px;
    height:200px;
    position:absolute;
    top:0;
    left:17px;
    display:none;
    @include isaac-respond-min-width(xs) {
      display:inline-block;
    }
    @include isaac-respond-min-width(md) {
      left:0;
    }
    
    background-image:url(img/logo.png);
    @include hide-text();
    z-index:2;
    pointer-events:none; 
  }


  .tagline-nl,
  .tagline-en,{
    width:195px;
    height:76px; //90 is too high, since the carousel has a negative offset
    background-image:url(img/daan-van-oort-fotografie.png);
    background-size: cover;
    @include hide-text();
    display:block;
    margin-left:0;
    @include isaac-respond-min-width(sm) {
      margin-left:40px;
    }
    @include isaac-respond-min-width(xs) {
       margin-left:140px;
    }

    @include isaac-respond-min-width(md) {
      margin-left:123px;
      width:225px;
      height:88px; //90 is too high, since the carousel has a negative offset
      background-size: auto;
    }
  }

  .tagline-en {
    background-image:url(img/daan-van-oort-photography.png);

  }

  .nav-metamenu {
    display:inline-block;
    clear:both;
  }

  .form-search {
    clear:both;
    width:250px;
  }
  #searchbar {
    position:absolute;
    width:100%;
    background-color:$gray-light;
    z-index:$zindex-dropdown;
    top:70px;
    form {
      margin:20px 10px;
    }
    
    /*on IE and FF the height is 1px too large, fix it here*/
    .btn {
      height: 36px;
      line-height:1em;
    }
    
  }
}

