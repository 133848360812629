footer {
  background-color:$brand-tertiary;

  border-top:4px solid $brand-primary;
  margin-top:50px;
  color:$brand-secondary;
  * {
    color:$brand-secondary;
  }
  
  .footer-menu {
    margin-top:40px;
    text-align:center;
    @include isaac-respond-min-width(md) {
      margin-top:20px;
      text-align:left;
    }
    .container {
      background:url(img/bird.png) no-repeat bottom right;

      .footer-copy {
        font-size:12px;
        padding:30px 0 60px 0;
        text-align:center;
        @include isaac-respond-min-width(md) {
          padding-bottom:10px;
          text-align:left;
        }
        a {
          text-decoration: underline;
        }
      }

    }
  }
  
  
}



