@font-face {
    font-family: 'OpenSans-Regular';
    src: url('../fonts/OpenSans/OpenSans-Regular-webfont.eot');
    src: url('../fonts/OpenSans/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans/OpenSans-Regular-webfont.woff') format('woff'),
         url('../fonts/OpenSans/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


h3, h4, h5, h6 {
  color:$brand-secondary;
  > a {
    color:$brand-secondary;
  }
}

