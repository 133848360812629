.btn {
  @include button-size(10px, 15px, 15px, 1.5em, 5px);

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    @include opacity(.5);
  }
  
  //icons inside buttons
  [class^="icon-"], [class*=" icon-"] {
    //position: relative;
    //top: 1px;
    //display: inline-block;
  }
}

.btn-search {
  @include button-size(6px, 12px, 15px, 1.5em, 5px);
}

.btn-primary-outline {
  border-color:$brand-primary;
  color:#fff;
  background-color: $brand-primary;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: darken($brand-primary, 8%);
    border-color: darken($brand-primary, 8%);
  }

}

