//see https://dev.twitter.com/docs/embedded-timelines
a.twitter-timeline {
  //hide flashing link (a will be replaced with an iframe)
  visibility:hidden;
  height:305px; //should be 5px larger then the height specified in <a class="twitter-timeline">
  display:block;
}

iframe.twitter-timeline,
a.twitter-timeline{
  width:100%!important;
  margin-top:30px;
}