//fix flor flickering issue on iPad 1
//http://stackoverflow.com/questions/2946748/iphone-webkit-css-animations-cause-flicker
.carousel-inner  > .item {
  -webkit-backface-visibility: hidden;
}

#carousel-wrapper {
  position:relative;
  margin: 0 (- $grid-gutter-width / 2) 10px (- $grid-gutter-width / 2);
  @include isaac-respond-min-width(md) {
    margin: 0 (- $grid-gutter-width) -57px (- $grid-gutter-width);
    top:-67px;
  }
}

.carousel-inner-wrapper {
  position:relative;
}

.carousel-caption {
  left: auto;
  right: auto;
  top: 10px;
  bottom: auto;
  position: relative;

  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.8);
  padding: 20px 10px;
  color:inherit;

  text-align: left;
  text-shadow: none;
  z-index: 0;

  @include isaac-respond-min-width(md) {
      left: 0;
      right: 48%;
      top: 140px;
      bottom: auto;
      position: absolute;
      padding: 20px;
  }

  h1 {
    margin-top:-10px;
  }

  .link-more {
    margin-top:5px;
    &::after {
      content: " \00BB";
    }

  }

  > .btn {
    margin-top:10px;
    margin-bottom:10px;
    width:100%;
    @include isaac-respond-min-width(sm) {
      margin-bottom:0;
      width:auto;
    }

  }
}

.carousel-indicators {
  bottom: 0;

  li {
    opacity:0.8;
  }
  .active {
    opacity:0.7;
  }
}


.carousel-control {

  &.left {
    background-image: none;
    &:hover {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }
  &.right {
    background-image: none;
    &:hover {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    }
  }

  .icon-prev {
    left: 37px;
    @include isaac-respond-min-width(md) {
        display:none;
    }
  }
  .icon-next {
    right: 20px;
  }
}
