.nav-wrapper {
  position:relative;
  z-index:1;
  display:block;
  padding:$grid-gutter-width 0 0 0;
}

// Open dropdowns
.nav {
  .open > a {
    &,
    &:hover,
    &:focus {
      border-color: inherit;
    }
  }

}

.dropdown-menu {
  > li > a {
    color:$brand-secondary;
    padding: 7px 8px;
    &:hover,
    &:focus {
      color:$brand-secondary;
    }
     
  }
}

//main menu
.nav-main.nav-pills {
  border-top:4px solid $brand-primary;
  background-color:$brand-secondary;
  
  > li > a {
    &,
    &:visited,
    &:focus,
    &:hover,
    &:active {
      color: #fff;
    }

    border-right:1px solid darken($brand-secondary,10%);
    border-left:1px solid lighten($brand-secondary,10%);
    border-radius: 0;
    padding: 8px 8px 11px 8px;
  }
  
  > li:first-child > a {
    border-left:0;
    padding-left:101px;
    border-right:0;
  }

  > li:nth-child(2) > a {
    border-left:0;    
  }

  li:last-child > a {
    border-right:0;
  }
  
  > li + li {
    margin-left: 0;
  }
  
  > li > .dropdown-menu {
    margin-top: 0;
    @include border-top-radius(0);
  }
  
}


//meta menu
.nav-metamenu .nav-pills {
  > li > a {
    background-color:transparent;
    padding: 10px 0 5px 15px;
    color:$brand-secondary;
    &:hover,
    &:focus {
      background-color:transparent;
      color:$brand-secondary;
      text-decoration:underline;
    }      
  }
  
  .dropdown-menu {
    left:auto;
    right:0px;
  }
}


.nav-aside {
  > li > a {
    color:$brand-secondary;
    padding: 7px 8px;
    &:hover,
    &:focus {
      color:$brand-secondary;
      background-color: $brand-tertiary;
    }

  }
  &.nav-stacked > li + li {
    margin-top: 0;
  }
}

.nav-woonplaats {
  // Woonplaats bord
  > li > a {
    font-family: 'Overpass', sans-serif;
    line-height: 1;
    border-radius: 5px;
    font-size: 20px;
    letter-spacing: 2px;
    text-align: center;
    padding:11px 15px 8px;
  }
  
  > li.active > a {
    background-color: lighten(#1f79ba,12%);

    &:hover, &:focus {
      background-color: #1f79ba;
    }
  
    &:after {
      content: " ";
      display: block;
      border-radius: 4px;
      position: absolute;
      z-index: 0;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      border: 2px solid #fff;
    }
  }
}

//offcanvas menu
.nav-offcanvas {
  > li > a {
    border-radius:0;
    color:#fff;
    border-bottom:1px solid lighten($brand-secondary,10%);
    &:hover,
    &:focus {
      color:#fff;
      @include box-shadow($offcanvas-shadow);
    }      
  }
  
  > li.active > a {
    @include box-shadow($offcanvas-shadow);
  }
  
  > li + li {
    margin-top:0;
  }
  
  /*submenu*/
  > li > ul {
    list-style: none;
    padding-left:0;
    > li > a {
        position: relative;
        display: block;
        padding: $nav-link-padding;
        padding-left:18px;
        border-bottom:1px solid lighten($brand-secondary,10%);
        color:#fff;
        &:hover,
        &:focus {
          text-decoration: none;
          background-color: $nav-link-hover-bg;
          @include box-shadow($offcanvas-shadow);
        }
        
        &:before {
          content:"\00BB  ";
        }
    }
    
    > li.active > a {
      color:#fff;
      background-color: $brand-primary;
      @include box-shadow($offcanvas-shadow);
    }
    
  }


}

.nav-offcanvas-language {
  margin-bottom:20px;
  border-bottom:0;

  > li {
    width:50%;
    + li {
      margin-left: 0;
    }

    > a {
      background-color:$gray-dark;
      border-radius:0;
      color:#fff;
      &:hover,
      &:focus {
        color:#fff;
        @include box-shadow(none);
      }
    }

    &.active > a {
      background-color:$brand-secondary;
      &:hover,
      &:focus {
        background-color:$brand-secondary;
      }
    }

  }
}

.social-links > ul {
    display:inline-block;
    margin: 30px auto 0 auto;
    @include isaac-respond-min-width(md) {
      margin-top:15px;
    }

    padding: 0;
    
    > li {
      margin:0 0 0 0;
      padding: 0 4px 0 0;

      float: left;
      list-style: none;
      position:relative;
      display:block;


      > a {
        color:$gray;
        text-decoration:none;
        &:hover {
          color:#000;
        }
      }
      
      > a [class^="icon-"] {
        font-size:30px;
      }
    }
    
}

