// website without offcanvas menu is contained within #content
#content {
  background-color: $content-bg;
  box-shadow: 0 0 10px -2px rgba(0,0,0,0.5);
}

main {
  padding:0 0 20px 0;
}

.row-homepage-content {
  margin-bottom:20px;
}

article {
  img {
    @extend .img-responsive;
  }
}