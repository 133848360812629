//form
.form-control {
  font-size: 16px; //prevent iPhone from zooming in when clicking an input that has a font-szie smaller then 16px 
}

.has-error {    //Bootstrap makes all these element red if the form-group is in error state
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline  {
    color: inherit;
  }
  //errors, triggered by javascript validation
  label.error {
  	@extend .help-block; //inherit margin from help-block
  	font-weight:bold;
  	color:$state-danger-text;
  }
}

label {
  font-weight: normal;
}


.form-contact {
  margin-top:40px;
}