$aspect-ratio: 720/480;
$item-spacing: 1px;

.gallery {
  display:flex;
  flex-wrap: wrap;
  margin: 0 -$item-spacing;
  
  .gallery-sizer, //for masonry layout
  .gallery-item {
    padding: $item-spacing;
    
    width: 100%;

    @media only screen and (min-width: 576px) {
      width: 50%;
    }

    @media only screen and (min-width: 992px) {
      width: 33.3333%;
    }
    
  }
  
  .gallery-sizer {
    padding:0;
  }

  .gallery-link {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
    padding-bottom: percentage(1/$aspect-ratio);
    overflow:hidden;
    
    .gallery-img {
      position: absolute;
      top:0;
      left:0;
      bottom:0;
      width: 100%;
      height:100%;
      background-size: cover;
      transition: transform 400ms, opacity 400ms;
      opacity: 1;

      &.lazy {
        opacity:0;
      }
    }
    
    &:hover, &:active, &:focus {
      .gallery-img {
        transform: scale(1.1);
      }
    }
  }
  
}