//lists
ul.hlist, ol.hlist {
  // TODO: replace Compass mixin @include horizontal-list(6px);
}

ul.vlist, ol.vlist {
  padding-left:0;
  list-style: none;
  li {
    list-style-image: none;
    list-style-type: none;
    margin-left: 0;
  }
}


ul.thumbgrid, ol.thumbgrid {
  // TODO: replace Compass mixin  @include horizontal-list(0);
  margin:0 -10px; //compensate for li margin
  > li {
    margin: 0 10px 10px 10px;
    > a {
      text-align:center;
      display:block;
      width:200px;
      height:200px;
      background-repeat:no-repeat;
      background-position:center center;
      background-size:cover;
      border-radius: 5px;
      opacity: 0.8;
    }
    > a:hover {
      opacity: 1;
    }
  }
}


//terms
ul.pagination-terms > li > * {
  padding:0;
  width:21px;
  text-align:center;
}


//link lists
ul.list-links {
    list-style-type:none;
    padding:0;
    margin:0;
    display:block;
    position:relative;
    overflow:hidden;
    > li {
      display:block;
      &:before {
        content: "\00BB";
        padding-right:4px;
      }
    }
  }

//downloads
ul.list-downloads {
  padding-left:0;
  list-style: none;
  
  > li {
    border-bottom: 1px solid $brand-tertiary;
    list-style-image: none;
    list-style-type: none;
    margin-left: 0;

    &:last-child {
      border-bottom:0;
    }

    > a {
      height:50px;
      padding:12px 0 0 55px;
      display:block;
      color:#432908;
      text-decoration:none;
      line-height: 20px;

      &:hover, &:focus {
        color:$brand-primary;
      }

      &.download-pdf {
        background: url(img/sprite-downloads.png) no-repeat 3px  5px ;
      }

      &.download-mp4 {
        background: url(img/sprite-downloads.png) no-repeat 3px  -45px ;
      }


      &.download-xls,
      &.download-xlsx {
        background: url(img/sprite-downloads.png) no-repeat 3px  -95px ;
      }

      &.download-doc,
      &.download-docx {
        background: url(img/sprite-downloads.png) no-repeat 3px  -145px ;
      }

      &.download-url {
        background: url(img/sprite-downloads.png) no-repeat 3px  -245px ;
      }
    }

  }
}
ul.list-downloads-lg {
  margin-bottom:40px;
  > li {
    border-bottom: none;
  }
}

//terms
ul.searchresult {
  margin-top:30px;
  > li {
    padding:0 0 5px 0;
  }

}

