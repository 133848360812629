.panel-default {
  border:1px solid $gray-light;
  border-radius: 8px;


  .panel-heading {
    padding:0;
    display:none;
    background:transparent;
    border-color:$gray-light;
    @include isaac-respond-min-width(sm) {
      display:block;
    }

    > img {
      width:100%;
      @include border-top-radius(7px);
    }
  }

  .panel-body {
     h2 {
       margin-top:0;
     }
      &.equal-heights {
        @include isaac-respond-max-width(xs) {
          min-height:inherit!important;//override js equal height calculation
        }
      }

   }

   .panel-footer {
     border-top:0;
     padding:0 15px 5px 15px;
     text-align:right;
     @include border-bottom-radius(8px);
      a::after {
        content: " \00BB";
      }
   }
}


.panel-downloads {
  margin-top:60px;
}