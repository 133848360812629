@font-face {
  font-family: 'icomoon';
  src:url('../fonts/icomoon/icomoon.eot?-6naqvs');
  src:url('../fonts/icomoon/icomoon.eot?#iefix-6naqvs') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.woff?-6naqvs') format('woff'),
    url('../fonts/icomoon/icomoon.ttf?-6naqvs') format('truetype'),
    url('../fonts/icomoon/icomoon.svg?-6naqvs#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-prev:before {
  font-family: 'icomoon';     //set font since jumbotron overrides this
  content: "\e601"!important; //important since jumbotron overrides this content
}

.icon-next:before {
  font-family: 'icomoon';
  content: "\e602"!important;
}
.icon-house:before {
  content: "\e622";
}
.icon-facebook2:before {
  content: "\e6f7";
}
.icon-linkedin2:before {
  content: "\e700";
}
.icon-mail:before {
  content: "\e600";
}
.icon-twitter2:before {
  content: "\e6f5";
}
.icon-search:before {
  content: "\e603";
}
.icon-instagram:before {
  content: "\e900";
}
.icon-flickr:before {
  content: "\e901";
}
